import GatewayAPI from '@/api/gateway';
import CallbacksAPI from '@/api/callbacks';

const state = {
  transactions: [],
  callbackAttempts: [],
  transactionsTotal: 0,
  transactionsPage: 1,
  transactionsTotalPages: 1,
  callbackAttemptsTotal: 0,
  callbackAttemptsPage: 1,
  callbackAttemptsTotalPages: 1,
};

const mutations = {
  SET_TRANSACTION(state, transaction) {
    state.transactions = transaction ? [transaction] : [];
    state.transactionsTotal = 1;
    state.transactionsPage = 1;
    state.transactionsTotalPages = 1;
  },
  SET_TRANSACTIONS(state, { transactions, total, page, totalPages }) {
    state.transactions = transactions;
    state.transactionsTotal = total;
    state.transactionsPage = page;
    state.transactionsTotalPages = totalPages;
  },
  SET_CALLBACK_ATTEMPTS(state, { callbackAttempts, total, page, totalPages }) {
    state.callbackAttempts = callbackAttempts;
    state.callbackAttemptsTotal = total;
    state.callbackAttemptsPage = page;
    state.callbackAttemptsTotalPages = totalPages;
  },
};

const actions = {
  async fetchTransactionById({ commit }, transactionId) {
    try {
      commit('SET_TRANSACTION', null);
      const response = await GatewayAPI.getTransactionById(transactionId);
      commit('SET_TRANSACTION', response.data);
      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        commit('SET_TRANSACTION', null);
        return null;
      }

      throw error;
    }
  },
  async fetchTransactionByReferenceId({ commit }, referenceId) {
    try {
      commit('SET_TRANSACTION', null);
      const response = await GatewayAPI.getTransactionByReferenceId(referenceId);
      commit('SET_TRANSACTION', response.data);
      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        commit('SET_TRANSACTION', []);
        return null;
      }

      throw error;
    }
  },
  async fetchTransactionsByCollabId({ commit }, { collabId, transactionType, transactionStatus, page, size, startDate, endDate }) {
    try {
      const response = await GatewayAPI.getTransactionsByCollabId(
          collabId, transactionType, transactionStatus, page, size, startDate, endDate);
      commit('SET_TRANSACTIONS', {
        transactions: response.data.transactions,
        total: response.data.totalCount,
        page: response.data.currentPage,
        totalPages: response.data.totalPages,
      });
      return response.data;
    } catch (error) {
      // Handle error
    }
  },
  async fetchCallbackAttemptsByTransactionId({ commit }, { transactionId, page, size }) {
    try {
      const response = await GatewayAPI.getCallbackAttemptsByTransactionId(transactionId, page, size);
      commit('SET_CALLBACK_ATTEMPTS', {
        callbackAttempts: response.data.callbackAttempts,
        total: response.data.totalCount,
        page: response.data.currentPage,
        totalPages: response.data.totalPages,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async fetchCallbackAttemptsByReferenceId({ commit }, { referenceId, page, size }) {
    try {
      const response = await GatewayAPI.getCallbackAttemptsByReferenceId(referenceId, page, size);
      commit('SET_CALLBACK_ATTEMPTS', {
        callbackAttempts: response.data.callbackAttempts,
        total: response.data.totalCount,
        page: response.data.currentPage,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async retryCallbackAttempt(_, callbackAttemptId) {
    try {
      await CallbacksAPI.postRetryCallback(callbackAttemptId);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async initializeTransaction(_, transaction) {
    try {
      const response = await GatewayAPI.postInitTransaction(transaction);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

// Path: src/store/transactions.js
