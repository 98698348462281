import axios from "axios";

const BASE_URL = process.env.VUE_APP_GATEWAY_API_BASE_URL;

export default {

  getStatistics(currency, from, to) {
    return axios.get(`${BASE_URL}/api/v1/statistics?currency=${currency}&from=${from}&to=${to}`);
  },

  /* Returns List<CollabDto>:
    [
      {
        "id": 0,
        "provider": {
          "id": 0,
          "name": "string",
          "currencies": [
            {
              "id": 0,
              "code": "string"
            }
          ]
        },
        "merchant": {
          "id": 0,
          "name": "string",
          "hash": "string",
          "collabsValidationUrl": "string"
        },
        "isEnabled": true,
      }
    ]
  */
  getCollabsByMerchantId(merchantId) {
    return axios.get(`${BASE_URL}/api/v1/merchants/${merchantId}/collabs`);
  },

  /* Returns CollabSettingsDto:
     
  {
    "collabId": 0,
    "cashOutValidationEndpointUrl": "string",
    "cashInValidationEndpointUrl": "string",
    "isEnabled": true,
    "validateCashOut": true,
    "validateCashIn": true,
    "isMerchantCallbackEnabled": true,
    "isCreditingToClientsEnabled": true,
    "isWithdrawFromClientsEnabled": true
  }

  */
  getCollabSettings(collabId) {
    return axios.get(`${BASE_URL}/api/v1/collabs/${collabId}/settings`);
  },

  /* Returns TransactionDto:

  {
    "id": 0,
    "collabId": 0,
    "providerId": 0,
    "providerName": "string",
    "merchantId": 0,
    "merchantName": "string",
    "collabClientId": 0,
    "accountData": "string",
    "nonce": "string",
    "providerTransactionId": "string",
    "amount": 0,
    "currency": "string",
    "createdUtc": "2024-04-25T11:53:37.370Z",
    "updatedUtc": "2024-04-25T11:53:37.370Z",
    "transactionType": 0,
    "transactionStatus": 0,
    "transactionStatusMessage": "string",
    "providerTransactionData": "string",
    "callbackDeliveryStatus": "string"
  }

  */
  getTransactionById(transactionId) {
    return axios.get(`${BASE_URL}/api/v1/transactions/${transactionId}`);
  },

  /* Returns TransactionDto (same as for getTransactionById(transactionId)) */
  getTransactionByReferenceId(referenceId) {
    return axios.get(`${BASE_URL}/api/v1/transactions/${referenceId}`);
  },

  /* Returns GetTransactionsPageResponse
  
  {
    "totalCount": 0,
    "currentPage": 0,
    "totalPages": 0,
    "transactions": [
      {
        "id": 0,
        "providerId": 0,
        "providerName": "string",
        "merchantId": 0,
        "merchantName": "string",
        "accountData": "string",
        "nonce": "string",
        "providerTransactionId": "string",
        "amount": 0,
        "currency": "string",
        "createdUtc": "2024-04-25T12:32:59.850Z",
        "updatedUtc": "2024-04-25T12:32:59.850Z",
        "transactionType": 0,
        "transactionStatus": 0,
        "transactionStatusMessage": "string",
        "providerTransactionData": "string",
        "callbackDeliveryStatus": 0
      }
    ]
  }
  
  */
  getTransactionsByCollabId(collabId, transactionType, transactionStatus, page, size, startDate, endDate) {

    if (!transactionType) {
      transactionType = 0;
    }

    var query = `?page=${page}&take=${size}&type=${transactionType}`;

    if (transactionStatus) {
      query += `&status=${transactionStatus}`;
    }

    if (startDate){
        query += `&from=${startDate}`;
    }

    if (endDate){
        query += `&to=${endDate}`;
    }

    return axios.get(
      `${BASE_URL}/api/v1/collabs/${collabId}/transactions${query}`
    );

  },

  /* Returns GetCallbackAttemptPageResponse 
  
  public class GetCallbackAttemptPageResponse
  {
      public List<CollabCallbackAttemptDto> CallbackAttempts { get; set; }
      public int TotalItemsCount { get; set; }
      public int Page { get; set; }
      public int TotalPages { get; set; }
  }

  public class CollabCallbackAttemptDto
  {
      public int Id { get; set; }
      public string RequestBody { get; set; }
      public int ResponseCode { get; set; }
      public string ResponseBody { get; set; }
      public DateTime CreatedUtc { get; set; }
      public string CallbackAttemptStatus { get; set; }
  }
  
  */
  getCallbackAttemptsByTransactionId(transactionId, page, size) {
    return axios.get(
      `${BASE_URL}/api/v1/transactions/${transactionId}/callbacks/attempts?page=${page}&take=${size}`
    );
  },

  /* Returns GetCallbackAttemptPageResponse (same as for getCallbackAttemptsByTransactionId(transactionId, page, size)) */
  getCallbackAttemptsByReferenceId(referenceId, page, size) {
    return axios.get(
      `${BASE_URL}/api/v1/transactions/${referenceId}/callbacks/attempts?page=${page}&take=${size}`
    );
  },

  /* Returns 200 OK */
  postProviderActiveness(providerId, isEnabled) {
    return axios.post(
      `${BASE_URL}/api/v1/providers/${providerId}/activeness?isEnabled=${isEnabled}`
    );
  },

  /* Returns 200 OK */
  postCollabSettings(collabId, settings) {
    return axios.post(`${BASE_URL}/api/v1/collabs/settings`, settings);
  },

  /* Returns 202 Accepted */
  enqueueTransactionCallback(transactionId) {
    return axios.post(
      `${BASE_URL}/api/v1/transactions/${transactionId}/callbacks/enqueue`
    );
  },

  /* callback URLs */

  getCallbackUrlsByMerchantId(merchantId) {
    return axios.get(`${BASE_URL}/api/v1/merchants/${merchantId}/callbacks`);
  },

  postCallbackUrl(callbackUrl) {
    return axios.post(
      `${BASE_URL}/api/v1/callbacks`,
      callbackUrl
    );
  },

  updateCallbackUrl(callbackUrlId, callbackUrl) {
    return axios.post(`${BASE_URL}/api/v1/callbacks/${callbackUrlId}`, callbackUrl);
  },

  deleteCallbackUrl(callbackUrlId) {
    return axios.delete(`${BASE_URL}/api/v1/callbacks/${callbackUrlId}`);
  },

  /* for admin only */

  /* Returns List<MerchantDto>:
  
  public class MerchantDto
  {
      public int Id { get; set; }
      public string Name { get; set; }
      public string Hash { get; set; }
      public string CollabsValidationUrl { get; set; }
  }
  
  */
  getMerchants() {
    return axios.get(`${BASE_URL}/api/v1/merchants`);
  },

  /* Returns List<CollabDto> (same as for getCollabsByMerchantId(merchantId)) */
  getCollabsByProviderId(providerId) {
    return axios.get(`${BASE_URL}/api/v1/providers/${providerId}/collabs`);
  },

  /* Returns List<ProviderDto> */
  getProviders() {
    return axios.get(`${BASE_URL}/api/v1/providers`);
  },

  /* Returns 202 Accepted */
  enqueueTransaction(transactionId) {
    return axios.post(
      `${BASE_URL}/api/v1/transactions/${transactionId}/enqueue`
    );
  },

  getValidationEndpointsByCollabId(collabId) {
    return axios.get(
      `${BASE_URL}/api/v1/collabs/${collabId}/validation-endpoints`
    );
  },

  createValidationEndpoint(validationEndpoint) {
    return axios.post(
      `${BASE_URL}/api/v1/collabs/validation-endpoints`, validationEndpoint
    );
  },

  updateValidationEndpoint(validationEndpointId, validationEndpoint) {
    return axios.post(
      `${BASE_URL}/api/v1/collabs/validation-endpoints/${validationEndpointId}`, validationEndpoint
    );
  },

  deleteValidationEndpoint(validationEndpointId) {
    return axios.delete(
      `${BASE_URL}/api/v1/collabs/validation-endpoints/${validationEndpointId}`
    );
  },

  postInitTransaction(transaction) {
    return axios.post(
      `${BASE_URL}/api/v1/transactions/initialize`, transaction
    );
  },
};
