<template>
  <div class="merchants">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="mb-4">Merchants</h2>
          <div v-if="!isAdmin">
            <p class="text-danger">You don't have permission to view this page.</p>
          </div>
          <template v-else>
            <div v-if="isLoading" class="text-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <template v-else>
              <div class="card mb-4 shadow" :class="{ 'bg-dark': isDarkTheme }">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-hover mb-0" :class="{ 'table-dark': isDarkTheme }">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Hash</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="merchant in merchants" :key="merchant.id">
                          <td>{{ merchant.id }}</td>
                          <td>{{ merchant.name }}</td>
                          <td>{{ merchant.hash }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <div v-if="error" class="text-danger mt-4">{{ error }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'MerchantsPage',
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('merchants', ['merchants']),
    isAdmin() {
      return this.user && this.user.roles.includes('Admin');
    },
    isDarkTheme() {
      return this.$store.state.isDarkTheme;
    },
  },
  async created() {
    if (this.isAdmin) {
      try {
        this.isLoading = true;
        await this.fetchMerchants();
      } catch (error) {
        this.error = 'Failed to fetch merchants. Please try again.';
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    }
  },
  mounted() {
    document.title = "Merchants";
  },
  methods: {
    ...mapActions('merchants', ['fetchMerchants']),
  },
};
</script>
<style scoped>
.merchants {
  padding: 60px 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.table {
  background-color: var(--bg-color);
  color: var(--text-color);
}
</style>