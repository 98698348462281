<template>
  <div class="page-container">
    <div class="validations">
      <div class="container-fluid">
        <h2 class="mb-4">Validation Endpoints</h2>
        <div v-if="!isAdmin && !user.merchantId">
          <p class="text-danger">You don't have permission to view this page.</p>
        </div>
        <template v-else>
          <div class="table-container table-responsive" :class="{ 'dark-theme': isDarkTheme }">
            <DataTable lazy v-model:editingRows="editingRows" :value="validationEndpoints" dataKey="id" :loading="isLoading"
              responsiveLayout="stack" :rowHover="true" :loading-text="'Loading validation endpoints...'"
              :resizableColumns="true" style="width: 100%" editMode="row" @row-edit-save="onRowEditSave"
              showGridlines>
              <template #header>
                <div class="table-header p-fluid p-2 m-2">
                  <div class="p-field">
                    <label for="collab-select" class="pb-2">{{ isAdmin ? 'Collab' : 'Provider' }}</label>
                    <Dropdown id="collab-select" v-model="selectedCollabId" checkmark
                      :disabled="isLoading || search !== ''" :options="formattedCollabs" optionValue="id"
                      optionLabel="label" :filter="true" :placeholder="isAdmin ? 'Select Collab' : 'Select Provider'"
                      class="w-full" :class="{ 'p-input-dark': isDarkTheme }" style="width: fit-content; min-width: 15rem;">
                      <template #option="slotProps">
                        <div v-if="isAdmin" class="flex align-items-center">
                          {{ slotProps.option.merchant.name }} - {{ slotProps.option.provider.name }}
                        </div>
                        <div v-else>
                          {{ slotProps.option.provider.name }}
                        </div>
                      </template>
                    </Dropdown>
                  </div>
                  <div class="p-field" style="max-width: min-content; margin-top: -10px;">
                    <Button label="Clear" icon="pi pi-filter-slash" @click="clearFilters" text />
                    <Button label="Search" icon="pi pi-search" @click="fetchValidationEndpoints" />
                    <Button icon="pi pi-plus" label="Add" class="p-button-success mt-3 mr-2" @click="openAddDialog" />
                  </div>
                </div>
              </template>
              <template #empty>
                <div v-if="!isLoading" class="d-flex justify-content-center m-3">
                  <p>No validation endpoints to be displayed</p>
                </div>
              </template>

              <Column field="id" header="ID" :headerStyle="{ position: 'sticky', top: '0' }" class="id-column">
                <template #body="{ data }">
                  <div class="id-cell">{{ data.id }}</div>
                </template>
              </Column>
              <Column field="collabId" header="Collaboration" :options="loadedCollabs" optionLabel="label" optionValue="id">
                <template #editor="{ data, field }">
                  <Dropdown v-model="data[field]" :options="loadedCollabs" optionLabel="label" optionValue="id"
                    placeholder="Select a Collaboration" class="w-full" />
                </template>
              </Column>
              <Column field="url" header="URL">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" autofocus class="w-full" />
                </template>
              </Column>
              <Column field="awaitable" header="Awaitable">
                <template #body="{ data }">
                  <i class="pi"
                    :class="{ 'pi-check-circle text-green-500': data.awaitable, 'pi-times-circle text-red-500': !data.awaitable }"></i>
                </template>
                <template #editor="{ data, field }">
                  <ToggleButton v-model="data[field]" onIcon="pi pi-check" offIcon="pi pi-times" class="w-full" />
                </template>
              </Column>
              <Column field="forCashIn" header="Cash In">
                <template #body="{ data }">
                  <i class="pi"
                    :class="{ 'pi-check-circle text-green-500': data.forCashIn, 'pi-times-circle text-red-500': !data.forCashIn }"></i>
                </template>
                <template #editor="{ data, field }">
                  <ToggleButton v-model="data[field]" onIcon="pi pi-check" offIcon="pi pi-times" class="w-full" />
                </template>
              </Column>
              <Column field="forCashOut" header="Cash Out">
                <template #body="{ data }">
                  <i class="pi"
                    :class="{ 'pi-check-circle text-green-500': data.forCashOut, 'pi-times-circle text-red-500': !data.forCashOut }"></i>
                </template>
                <template #editor="{ data, field }">
                  <ToggleButton v-model="data[field]" onIcon="pi pi-check" offIcon="pi pi-times" class="w-full" />
                </template>
              </Column>
              <Column field="priority" header="Priority">
                <template #editor="{ data, field }">
                  <InputNumber v-model="data[field]" :min="1" :max="100" class="w-full" />
                </template>
              </Column>
              <Column :rowEditor="true" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center"></Column>
              <Column style="width: 4rem">
                <template #body="{ data }">
                  <Button icon="pi pi-trash" class="p-button-outlined p-button-danger" @click="confirmDelete(data)" />
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </div>

      <Dialog v-model:visible="addDialogVisible" header="Add Validation Endpoint" :modal="true" class="p-fluid validation-dialog"
      :maximizable="true" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '50vw', '775px': '90vw' }">
        <div class="p-grid p-ai-center">
          <div class="p-col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-link"></i></span>
              <InputText id="url" v-model="newValidationEndpoint.url" placeholder="URL"
                :class="{ 'p-invalid': submitted && !newValidationEndpoint.url }" />
            </div>
            <small class="p-error" v-if="submitted && !newValidationEndpoint.url">Endpoint URL is required.</small>
            <div class="p-text-secondary">Enter the URL where the validation request will be sent.</div>
          </div>
          <div class="p-col-12">
            <div class="p-inputgroup mt-3">
              <span class="p-inputgroup-addon"><i class="pi pi-sitemap"></i></span>
              <Dropdown id="collabId" v-model="newValidationEndpoint.collabId" :options="loadedCollabs" optionLabel="label"
                optionValue="id" placeholder="Select a Collaboration"
                :class="{ 'p-invalid': submitted && !newValidationEndpoint.collabId }" />
            </div>
            <small class="p-error" v-if="submitted && !newValidationEndpoint.collabId">Collaboration is required.</small>
            <div class="p-text-secondary p-mt-1">Choose the collaboration associated with this validation.</div>
          </div>
          <div class="p-col-12 p-md-6">
            <div class="p-inputgroup mt-3">
              <span class="p-inputgroup-addon"><i class="pi pi-replay"></i></span>
              <InputNumber id="priority" v-model="newValidationEndpoint.priority" :min="1" :max="100"
                placeholder="Priority" :class="{ 'p-invalid': submitted && !newValidationEndpoint.priority }" />
            </div>
            <small class="p-error" v-if="submitted && !newValidationEndpoint.priority">Priority is required.</small>
            <div class="p-text-secondary p-mt-1">Set the priority of validation endpoint.</div>
          </div>
          <div class="p-col-12">
            <div class="p-field-checkbox mt-3">
              <Checkbox id="awaitable" v-model="newValidationEndpoint.awaitable" :binary="true" />
              <label for="awaitable" class="ms-1">Awaitable</label>
            </div>
            <div class="p-text-secondary ms-4">Is endpoint awaitable.</div>
          </div>
          <div class="p-col-12">
            <div class="p-field-checkbox mt-3">
              <Checkbox id="forCashIn" v-model="newValidationEndpoint.forCashIn" :binary="true" />
              <label for="forCashIn" class="ms-1">Cash In</label>
            </div>
            <div class="p-text-secondary ms-4">Use this endpoint for cash in.</div>
          </div>
          <div class="p-col-12">
            <div class="p-field-checkbox mt-3">
              <Checkbox id="forCashOut" v-model="newValidationEndpoint.forCashOut" :binary="true" />
              <label for="forCashOut" class="ms-1">Cash Out</label>
            </div>
            <div class="p-text-secondary ms-4">Use this endpoint for cash out.</div>
          </div>
        </div>
        <template #footer>
          <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="addDialogVisible = false" />
          <Button label="Save" icon="pi pi-check" class="p-button-success" @click="saveNewValidationEndpoint" />
        </template>
      </Dialog>

      <ConfirmDialog>
        <template #message="slotProps">
          <div class="flex align-items-center">
            <i :class="slotProps.message.icon" style="font-size: 1.5rem; margin-right: 0.5rem;"></i>
            <span>{{ slotProps.message.message }}</span>
          </div>
        </template>
      </ConfirmDialog>
      <ConfirmPopup></ConfirmPopup>
      <Toast />
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import ToggleButton from 'primevue/togglebutton';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from "primevue/useconfirm";

export default {
  components: {
    DataTable,
    Column,
    Dropdown,
    Button,
    InputText,
    Dialog,
    Toast,
    ConfirmDialog,
    ConfirmPopup,
    InputNumber,
    ToggleButton,
    Checkbox
  },
  setup() {
    const toast = useToast();
    const confirm = useConfirm();
    return { confirm, toast };
  },
  name: 'ValidationEndpointsPage',
  data() {
    return {
      loadedCollabs: [],
      selectedCollabId: '',
      validationEndpoints: [],
      errorMessage: null,
      isLoading: false,
      isLoadingValidations: false,
      error: null,
      search: '',
      dateRange: null,
      editingRows: [],
      addDialogVisible: false,
      submitted: false,
      newValidationEndpoint: this.initNewValidationEndpoint(),
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('providers', ['providers']),
    ...mapState('collabs', {
      ...mapState('collabs', ['collabs']),
    }),
    isDarkTheme() {
      return this.$store.state.isDarkTheme;
    },
    isAdmin() {
      return this.user && this.user.roles.includes('Admin');
    },
    formattedCollabs() {
      return this.collabs.map(collab => ({
        ...collab,
        label: this.isAdmin
          ? `${collab.merchant.name} - ${collab.provider.name}`
          : collab.provider.name
      }));
    },
  },
  async created() {
    this.isLoadingCollabs = true;
    
    let response = [];
    try {
      if (this.isAdmin) {
        const providers = await this.fetchProviders();
        response = await this.fetchCollabsByProviderIds(providers.map(provider => provider.id));
      }
      else {
        response.unshift(await this.fetchCollabsByMerchantId(this.user.merchantId));
      }

      this.loadedCollabs = response.map(collab => ({
          id: collab.id,
          label: `${collab.merchant.name} - ${collab.provider.name}`,
        }));
    } catch (error) {
      this.error = 'Failed to load collaborations: ' + error.message;
    } finally {
      this.isLoadingCollabs = false;
    }
  },
  mounted() {
    document.title = "Validation Endpoints";
  },
  methods: {
    ...mapActions('providers', ['fetchProviders']),
    ...mapActions('collabs', [
      'fetchCollabsByMerchantId',
      'fetchCollabsByProviderIds']),
    ...mapActions('validationEndpoints', [
      'fetchValidationEndpointsByCollabId',
      'createValidationEndpoint',
      'updateValidationEndpoint',
      'deleteValidationEndpoint']),

    clearFilters() {
      this.selectedCollabId = '';
    },

    async loadCollabs() {
      try {
        this.errorMessage = null;
        this.isLoading = true;
        const response = await this.fetchCollabsByMerchantId(this.user.merchantId);
        this.loadedCollabs = response.map(collab => ({
          id: collab.id,
          label: `${collab.merchant.name} - ${collab.provider.name}`,
        }));
      } catch (error) {
        this.errorMessage = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async fetchValidationEndpoints() {
      try {
        this.error = null;
        this.isLoading = true;

        if (this.selectedCollabId) {
          const response = await this.fetchValidationEndpointsByCollabId(this.selectedCollabId);
          this.validationEndpoints = response
        }
      } catch (error) {
        this.error = 'Failed to fetch transactions. Please try again.';
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch transactions: ' + error.message, life: 5500 });
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    initNewValidationEndpoint() {
      return {
        collabId: null,
        url: '',
        priority: 1,
        awaitable: false,
        forCashIn: false,
        forCashOut: false,
      }
    },

    openAddDialog() {
      this.newCallbackUrl = this.initNewValidationEndpoint();
      this.submitted = false;
      this.addDialogVisible = true;
    },

    async saveNewValidationEndpoint() {
      this.submitted = true;

      if (this.newValidationEndpoint.collabId && this.newValidationEndpoint.url) {
        this.confirm.require({
          message: 'Are you sure you want to create a new validation endpoint?',
          header: 'Confirmation',
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          acceptClass: 'p-button-success',
          accept: async () => {
            try {
              this.toast.add({ severity: 'info', summary: 'Adding...', detail: 'Adding new validation endpoint', life: 3000 });
              const createdValidationEndpoint = await this.createValidationEndpoint({ collabId: this.selectedCollabId, validationEndpoint: this.newValidationEndpoint });
              this.validationEndpoints.unshift(createdValidationEndpoint);
              this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Validation endpoint created', life: 3000 });
              this.addDialogVisible = false;
            } catch (error) {
              this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to create validation endpoint: ' + error.message, life: 3000 });
            }
          },
        });
      }
    },

    async onRowEditSave(event) {
      const { newData, index } = event;

      if (newData.id) {
        this.confirm.require({
          message: 'Are you sure you want to update this validation endpoint?',
          header: 'Confirmation',
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          acceptClass: 'p-button-warning',
          accept: async () => {
            try {
              this.toast.add({ severity: 'info', summary: 'Updating...', detail: 'Updating validation endpoint', life: 3000 });
              const changedValidationEndpoint = await this.updateValidationEndpoint({ collabId: this.selectedCollabId, validationEndpointId: newData.id, validationEndpoint: newData });
              this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Validation endpoint updated', life: 3000 });
              this.validationEndpoints[index] = changedValidationEndpoint;
            } catch (error) {
              this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to update validation endpoint: ' + error.message, life: 3000 });
            }
          },
          reject: () => {
            // Revert changes
            this.validationEndpoints[index] = { ...this.validationEndpoints[index], ...event.data };
          }
        });
      } else {
        this.confirm.require({
          message: 'Are you sure you want to create a new validation endpoint?',
          header: 'Confirmation',
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          acceptClass: 'p-button-success',
          accept: async () => {
            try {
              this.toast.add({ severity: 'info', summary: 'Adding...', detail: 'Adding new validation endpoint', life: 3000 });
              const createdValidationEndpoint = await this.createValidationEndpoint({ collabId: this.selectedCollabId, validationEndpoint: newData });
              this.validationEndpoints[index] = createdValidationEndpoint;
              this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Validation endpoint created', life: 3000 });
            } catch (error) {
              this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to create validation endpoint: ' + error.message, life: 3000 });
            }
          },
          reject: () => {
            // Remove the new row
            this.validationEndpoints = this.validationEndpoints.filter(endpoint => endpoint.id > 0);
          }
        });
      }
    },

    confirmDelete(validationEndpoint) {
      this.confirm.require({
        message: 'Are you sure you want to delete this validation endpoint?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon: 'pi pi-trash',
        rejectIcon: 'pi pi-times',
        acceptClass: 'p-button-danger',
        accept: async () => {
          try {
            this.toast.add({ severity: 'info', summary: 'Deleting...', detail: 'Deleting validation endpoint', life: 3000 });
            await this.deleteValidationEndpoint({ collabId: this.selectedCollabId, validationEndpointId: validationEndpoint.id });
            this.validationEndpoints = this.validationEndpoints.filter(url => url.id !== validationEndpoint.id);
            this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Validation endpoint deleted', life: 3000 });
          } catch (error) {
            this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete validation endpoint: ' + error.message, life: 3000 });
          }
        }
      });
    },
  },
}
</script>

<style scoped>

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.validations {
  padding: 60px 10px 10px;
  display: flex;
  flex-direction: column;
}

.transaction-details pre {
  overflow-x: auto;
  max-height: 300px;
}

.table-container {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 1.2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  border: 2px solid #e0e0e0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.table-container.dark-theme {
  background-color: #18181B;
  color: rgb(248, 248, 248);
  border-color: #41414e;
}

.p-datatable .p-datatable-header {
  border: none !important;
  border-width: 0;
  margin: 1rem;
  height: max-content !important;
}

.table-header {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.table-header .p-field {
  flex: 1 1 300px;
}

.table-header .search-and-clear {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.search-field {
  flex: 3 1 60%;
}

.action-buttons {
  flex: 1 1 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-responsive {
  flex: 1;
  overflow: hidden;
}

.table-responsive .p-datatable-wrapper {
  flex: 1;
  overflow: auto;
}

.table-responsive .p-datatable-thead th,
.table-responsive .p-datatable-tbody td {
  white-space: nowrap;
}

@media (max-width: 600px) {
  .transactions-table .p-datatable-wrapper {
    overflow-x: auto;
  }

  table {
    font-size: 12px;
  }

  .transactions-table .p-datatable-thead th,
  .transactions-table .p-datatable-tbody td {
    white-space: nowrap;
  }

  .table-responsive .p-datatable-wrapper {
    overflow-x: auto;
  }

  .table-responsive .p-datatable-thead th,
  .table-responsive .p-datatable-tbody td {
    white-space: nowrap;
  }

  .table-header .p-field,
  .search-field,
  .action-buttons {
    flex: 1 1 100%;
  }

  .action-buttons {
    margin-top: 1rem;
  }

  .table-header,
  .table-container {
    padding: 0.5rem; 
  }
}
</style>